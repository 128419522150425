/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  width: 400px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
}

.shortened-url {
  margin-top: 20px;
}

.shortened-url h3 {
  font-size: 10px;
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
  word-wrap: break-word;
}

a:hover {
  text-decoration: underline;
}

/* Add a background image to the body */
body {
  background-image: url('/Users/sangjin/Desktop/Coding_Practice/ChatGPT_Web/frontend/src/greg-rakozy-oMpAz-DN-9I-unsplash.jpg'); 
  background-size: cover; /* Adjust the background image size */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-attachment: fixed; /* Fix the image in the viewport */
  background-position: center center; /* Center the background image both horizontally and vertically */
}

/* Style your content container */
.container {
  background-color: rgba(255, 255, 255, 0.8); /* Add a semi-transparent white background to your content */
  padding: 20px;
  border-radius: 10px; /* Add rounded corners to your content container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

/* Style the copy icon as a Bootstrap link button */
.copy-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: inherit; /* Inherit font size from parent (button) */
  color: #007bff; /* Set the icon color to Bootstrap's primary color */
}

/* Style the "Copied!" message */
.copy-message {
  color: green;
  font-size: 14px;
  margin-left: 5px;
}
