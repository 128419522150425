/* footer.css */
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px; /* Increase the padding to make the footer slightly bigger */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  
  .social-icons {
    display: flex;
  }
  
  .social-icon {
    color: white;
    text-decoration: none;
    margin-right: 10px;
    font-size: 20px;
  }
  
  .social-icon:last-child {
    margin-right: 0;
  }
  
  .social-icon:hover {
    color: #007bff;
  }
  